<script setup>
import { Carousel, Slide } from 'vue3-carousel';
import { ref, watch, defineProps } from 'vue';
const currentSlide = ref(0);
const props = defineProps(['summary']);
const next = () => {
	currentSlide.value++;
};
const prev = () => {
	currentSlide.value--;
};

const config = {
	itemsToShow: 1,
	snapAlign: 'center',
	wrapAround: true,
	breakpointMode: 'viewport',
	breakpoints: {
		640: {
			itemsToShow: 1.3,
			snapAlign: 'center',
		},
	},
};

watch(currentSlide, (newValue, oldValue) => {
	var element = document.getElementById('summary');
	element.classList.add(`summary-slide-${currentSlide.value}`);
	var el = document.getElementById('cancel');
	el.classList.add(`cancel-slide-${currentSlide.value}`);
	if (newValue == 0 && oldValue == 10) {
		element.classList.remove(`summary-slide-10`);
		el.classList.remove(`cancel-slide-10`);
		return;
	}
	if (newValue > oldValue) {
		element.classList.remove(`summary-slide-${currentSlide.value - 1}`);
		el.classList.remove(`cancel-slide-${currentSlide.value - 1}`);
	} else {
		element.classList.remove(`summary-slide-${currentSlide.value + 1}`);
		el.classList.remove(`cancel-slide-${currentSlide.value + 1}`);
	}
});
</script>

<template>
	<div class="block">
		<div class="summary summary-slide-0" id="summary">
			<div class="hello mb-8 px-12">
				Tổng kết dấu ấn năm 2024 - {{ props.summary?.org?.name }}
			</div>
			<div class="relative w-full">
				<Carousel v-bind="config" v-model="currentSlide">
					<Slide v-for="i in 11" :key="i">
						<div
							class="card-panel carousel__item relative"
							v-if="i == 1"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/1.png"
									alt=""
								/>
								<div class="mt-10 bold-text text-center">
									Xin chào Trung tâm<br />
									{{ props.summary?.org?.name }}
								</div>
								<div class="mt-6 text-center text">
									Thành viên thứ
									<span class="red-text font-bold">{{
										props.summary?.org?.order
									}}</span>
									của Luca Education, hãy cùng Luca nhìn lại
									năm {{ props.summary?.year }} của trung tâm
									mình nhé
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 2"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/2.png"
									alt=""
								/>
								<div class="mt-12 text-center text">
									Trung tâm hiện có
								</div>
								<div class="mt-10 bold-text text-center">
									<span class="red-text">{{
										props.summary?.student_stats
											?.student_count_on_end_year || 0
									}}</span>
									học sinh
								</div>
								<div
									class="mt-1 text-center bold-text fs-16"
									v-if="
										props.summary?.student_stats
											?.percentage_increase > 0
									"
								>
									Tăng
									{{
										props.summary?.student_stats
											?.percentage_increase || 0
									}}% so với 1/2024
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 3"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/3.png"
									alt=""
								/>
								<div class="mt-12 text-center text">
									Trung tâm hiện có
								</div>
								<div class="mt-12 bold-text text-center">
									<span class="red-text">{{
										props.summary?.staff_stats
											?.staff_count_on_end_year || 0
									}}</span>
									trị liệu viên
								</div>
								<div
									class="mt-2 text-center bold-text fs-16"
									v-if="
										props.summary?.staff_stats
											?.percentage_increase > 0
									"
								>
									Tăng
									{{
										props.summary?.staff_stats
											?.percentage_increase || 0
									}}% so với 1/2024
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 4"
						>
							<div class="flex flex-col items-center">
								<div
									class="flex flex-nowrap items-center gap-1"
								>
									<img
										src="@/assets/images/emoji/happy.png"
										alt=""
										width="24"
										height="24"
									/>
									<div class="bold-text fs-19">
										{{
											props.summary
												?.emotion_scale_stats[5] || 0
										}}%
									</div>
									<div class="text">Vui vẻ</div>
								</div>
								<div
									class="
										flex flex-nowrap
										items-center
										gap-1
										mt-1
									"
								>
									<img
										src="@/assets/images/emoji/5565d66a50b44df9ae37a955bdfb3757.png"
										alt=""
										width="24"
										height="24"
									/>
									<div class="bold-text fs-19">
										{{
											props.summary
												?.emotion_scale_stats[4] || 0
										}}%
									</div>
									<div class="text">Bình thường</div>
								</div>
								<div
									class="
										flex flex-nowrap
										items-center
										gap-1
										mt-1
									"
								>
									<img
										src="@/assets/images/emoji/cf1f643bc1244f5e8e37767bc946d607.png"
										alt=""
										width="24"
										height="24"
									/>
									<div class="bold-text fs-19">
										{{
											props.summary
												?.emotion_scale_stats[3] || 0
										}}%
									</div>
									<div class="text">Khó chịu</div>
								</div>
								<div
									class="
										flex flex-nowrap
										items-center
										gap-1
										mt-1
									"
								>
									<img
										src="@/assets/images/emoji/5ede936757cd4fbdb6247bad22bf454d.png"
										alt=""
										width="24"
										height="24"
									/>
									<div class="bold-text fs-19">
										{{
											props.summary
												?.emotion_scale_stats[2] || 0
										}}%
									</div>
									<div class="text">Tức giận</div>
								</div>
								<div
									class="
										flex flex-nowrap
										items-center
										gap-1
										mt-1
									"
								>
									<img
										src="@/assets/images/emoji/0b356bb507d64b9489e2fbced131f795.png"
										alt=""
										width="24"
										height="24"
									/>
									<div class="bold-text fs-19">
										{{
											props.summary
												?.emotion_scale_stats[1] || 0
										}}%
									</div>
									<div class="text">Bùng nổ</div>
								</div>

								<div class="mt-10 text-center text">
									Cảm xúc của các con trong
								</div>
								<div class="mt-6 bold-text text-center">
									12 tháng
								</div>
								<div class="mt-2 text-center bold-text fs-16">
									của năm 2024
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 5"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/5.png"
									alt=""
								/>
								<div class="mt-6 text-center text">
									Lớp bán trú có
								</div>
								<div class="mt-6 big-text text-center">
									<span class="red-text"
										>{{
											props.summary?.day_care_stats
												?.eat_well_percent || 0
										}}%</span
									>
									trẻ ăn tốt
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text"
										>{{
											props.summary?.day_care_stats
												?.sleep_well_percent || 0
										}}%</span
									>
									trẻ ngủ tốt
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text"
										>{{
											props.summary?.day_care_stats
												?.normal_day_care_health_percent ||
											0
										}}%</span
									>
									trẻ khoẻ mạnh
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 6"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/6.png"
									alt=""
								/>
								<div class="mt-10 text-center text">
									Trung tâm đã đăng
								</div>
								<div class="mt-10 big-text text-center">
									<span class="red-text">{{
										props.summary?.media_stats
											?.video_count || 0
									}}</span>
									video
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text">{{
										props.summary?.media_stats
											?.image_count || 0
									}}</span>
									hình ảnh
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 7"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/7.png"
									alt=""
								/>
								<div class="mt-10 text-center text">
									Trung tâm có
								</div>
								<div class="mt-8 bold-text text-center">
									<span class="red-text">{{
										props.summary?.session_hour_count || 0
									}}</span>
									giờ can thiệp
								</div>
								<div class="mt-2 text-center bold-text fs-16">
									trong 12 tháng của năm 2024
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 8"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/8.png"
									alt=""
								/>
								<div class="mt-6 text-center text">
									Trung tâm đã đăng
								</div>
								<div class="mt-6 big-text text-center">
									<span class="red-text">{{
										props.summary?.report_stats
											?.lesson_report_count || 0
									}}</span>
									báo cáo ngày
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text">{{
										props.summary?.report_stats
											?.weekly_report_count || 0
									}}</span>
									báo cáo tuần
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text">{{
										props.summary?.report_stats
											?.monthly_report_count || 0
									}}</span>
									báo cáo tháng
								</div>
								<div class="mt-1 big-text text-center">
									<span class="red-text">{{
										props.summary?.plan_count || 0
									}}</span>
									kế hoạch can thiệp
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 9"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/9.png"
									alt=""
								/>
								<div class="mt-8 text-center text">
									Trung tâm đã hoàn thành
								</div>
								<div class="mt-6 bold-text text-center">
									<span class="red-text"
										>{{
											props.summary?.goal_stats
												?.completed_percent || 0
										}}%</span
									>
									mục tiêu đề ra
								</div>
								<div
									style="line-height: 22px"
									class="mt-2 text-center bold-text fs-16"
								>
									Mục tiêu hoàn thành trung bình <br />
									trong
									{{
										props.summary?.goal_stats
											?.average_completion_time || 0
									}}
									ngày
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 10"
						>
							<div class="flex flex-col items-center">
								<img
									class="w-auto h-auto"
									src="@/assets/images/slide/10.png"
									alt=""
								/>
								<div class="mt-10 text-center text">
									Phụ huynh đã xem
								</div>
								<div class="mt-10 bold-text text-center">
									<span class="red-text">{{
										props.summary
											?.parent_read_report_count || 0
									}}</span>
								</div>
								<div class="mt-2 text-center bold-text fs-16">
									báo cáo
								</div>
							</div>
						</div>
						<div
							class="card-panel carousel__item relative"
							v-if="i == 11 && currentSlide !== 0"
						>
							<div class="flex flex-col items-center">
								<img
									alt="luca-logo"
									height="42"
									src="@/assets/images/logo/logo.svg"
									width="77"
								/>
								<img
									class="w-auto h-auto mt-1"
									src="@/assets/images/slide/11.png"
									alt=""
								/>
								<div class="mt-4 text-center text">
									Luca Education xin gửi lời cảm ơn chân thành
									đến
									<span class="bold">{{
										props.summary?.org?.name
									}}</span>
									vì đã cùng chúng tôi đồng hành và góp phần
									nâng cao chất lượng giáo dục đặc biệt.
								</div>
								<div class="mt-4 text-center text">
									Chúc quý thầy cô một năm mới tràn đầy hạnh
									phúc và bình an!
								</div>
							</div>
						</div>
					</Slide>
				</Carousel>
				<div class="wrap-arrow left-radius" v-if="currentSlide !== 0">
					<div class="arrow cursor-pointer left-arrow" @click="prev">
						<svg
							width="16"
							height="12"
							viewBox="0 0 16 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M7.27461 0.429889C7.58939 0.765649 7.57238 1.29301 7.23662 1.60779L3.44065 5.16651H14.6667C15.1269 5.16651 15.5 5.5396 15.5 5.99984C15.5 6.46008 15.1269 6.83317 14.6667 6.83317H3.44065L7.23662 10.3919C7.57238 10.7067 7.58939 11.234 7.27461 11.5698C6.95984 11.9056 6.43248 11.9226 6.09672 11.6078L0.763382 6.60779C0.59534 6.45025 0.5 6.23018 0.5 5.99984C0.5 5.7695 0.59534 5.54943 0.763382 5.39189L6.09672 0.391892C6.43248 0.077117 6.95984 0.0941288 7.27461 0.429889Z"
								fill="#E5A42B"
							/>
						</svg>
					</div>
				</div>
				<div class="wrap-arrow right-radius">
					<div class="arrow cursor-pointer right-arrow" @click="next">
						<svg
							width="16"
							height="12"
							viewBox="0 0 16 12"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M8.72539 0.429889C8.41061 0.765649 8.42762 1.29301 8.76338 1.60779L12.5593 5.16651H1.33333C0.873096 5.16651 0.5 5.5396 0.5 5.99984C0.5 6.46008 0.873096 6.83317 1.33333 6.83317H12.5593L8.76338 10.3919C8.42762 10.7067 8.41061 11.234 8.72539 11.5698C9.04016 11.9056 9.56752 11.9226 9.90328 11.6078L15.2366 6.60779C15.4047 6.45025 15.5 6.23018 15.5 5.99984C15.5 5.7695 15.4047 5.54943 15.2366 5.39189L9.90328 0.391892C9.56752 0.077117 9.04016 0.0941288 8.72539 0.429889Z"
								fill="#F0BB4F"
							/>
						</svg>
					</div>
				</div>
			</div>
		</div>
		<div
			class="
				flex
				items-center
				justify-center
				cancel cancel-slide-0
				cursor-pointer
			"
			id="cancel"
			@click="$emit('close')"
		>
			Đóng
		</div>
	</div>
</template>
<style scoped>
.block {
	border-radius: 16px;
	overflow: hidden;
}
.summary {
	width: 802px;
	height: 636px;
	padding: 60px 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	-webkit-transition: background-color 1.5s ease-out;
	-moz-transition: background-color 1.5s ease-out;
	-o-transition: background-color 1.5s ease-out;
	transition: background-color 1.5s ease-out;
}
.summary-slide-0 {
	background-color: #db5a41;
}
.summary-slide-1 {
	background-color: #e77b35;
}
.summary-slide-2 {
	background-color: #f0bb4f;
}
.summary-slide-3 {
	background-color: #2aa797;
}
.summary-slide-4 {
	background-color: #0891b2;
}
.summary-slide-5 {
	background-color: #93c5fd;
}
.summary-slide-6 {
	background-color: #2563eb;
}
.summary-slide-7 {
	background-color: #4f46e5;
}
.summary-slide-8 {
	background-color: #8b5cf6;
}
.summary-slide-9 {
	background-color: #e879f9;
}
.summary-slide-10 {
	background-color: #fca5a5;
}

.hello {
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	text-align: center;
	color: #ffffff;
}
.card-panel {
	width: 600px;
	height: 412px;
	padding: 40px 36px;
	background-color: #fff;
	border-radius: 12px;
}
.cancel {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	padding: 32px;
	-webkit-transition: background-color 1.5s ease-out;
	-moz-transition: background-color 1.5s ease-out;
	-o-transition: background-color 1.5s ease-out;
	transition: background-color 1.5s ease-out;
}
.cancel-slide-0 {
	color: #832c1b;
	background-color: #e68d7b;
}
.cancel-slide-1 {
	color: #7c3d14;
	background-color: #f1a267;
}
.cancel-slide-2 {
	color: #ba5b1f;
	background-color: #f6d692;
}
.cancel-slide-3 {
	color: #2d8579;
	background-color: #41bead;
}
.cancel-slide-4 {
	color: #0e7490;
	background-color: #06b6d4;
}
.cancel-slide-5 {
	color: #4477b2;
	background-color: #bfdbfe;
}
.cancel-slide-6 {
	color: #30547e;
	background-color: #3b82f6;
}
.cancel-slide-7 {
	color: #4338ca;
	background-color: #818cf8;
}
.cancel-slide-8 {
	color: #6d28d9;
	background-color: #a78bfa;
}
.cancel-slide-9 {
	color: #c026d3;
	background-color: #f0abfc;
}
.cancel-slide-10 {
	color: #ef4444;
	background-color: #fecaca;
}
.bold-text {
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	color: #344256;
}
.text {
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	color: #344256;
}
.wrap-arrow {
	width: 72px;
	height: 100%;
	/* background-color: #fff; */
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
}
.left-radius {
	border-radius: 0 16px 16px 0;
	left: 0;
}
.right-radius {
	border-radius: 16px 0 0 16px;
	right: 0;
}
.arrow {
	width: 36px;
	height: 36px;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #f0bb4f;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	z-index: 3;
}

.fs-16 {
	font-size: 16px !important;
	font-weight: 600px !important;
}
.fs-19 {
	font-size: 19px !important;
}
.red-text {
	color: #db5a41;
}
.big-text {
	font-size: 28px;
	font-weight: 700;
	line-height: 32px;
	color: #344256;
}
.yellow-text {
	color: #e5a42b;
}

@media only screen and (max-width: 640px) {
	.block {
		width: 100vw;
		padding: 16px;
	}
	.summary {
		width: 100%;
		height: auto;
		padding: 20px;
	}
	.card-panel {
		width: 360px;
		height: max-content;
	}
	.hello {
		margin-bottom: 12px;
	}
}
</style>
