<template>
	<Alert v-model:is-open="isOpen" :type="alert.type">{{
		alert.message
	}}</Alert>
	<AlertDontHide v-model:is-open="isOpenDontHide" :type="alertDontHide.type">
		<span v-html="alertDontHide.message"></span>
	</AlertDontHide>
	<component :is="layout" v-model:layout="layout">
		<router-view />
	</component>
	<DaModalFrame v-model:open="modalIsOpen">
		<new-release
			:release="release"
			@close="modalIsOpen = false"
		></new-release>
	</DaModalFrame>
	<DaModalFrame v-model:open="slideOpen">
		<slide-summary
			:summary="summary"
			@close="slideOpen = false"
		></slide-summary>
	</DaModalFrame>
</template>
<script setup>
import { _LAYOUT_NAME } from '@/enums';
import Alert from '@/components/elements/alert/Alert.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import AlertDontHide from '@/components/elements/alert/AlertDontHide.vue';
import { $api } from '@/services';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import NewRelease from '@/views/newRelease/newRelease.vue';
import SlideSummary from '@/views/slideSummary/slideSummary.vue';

const route = useRoute();
const store = useStore();
const isOpen = computed(() => store.state.alert.message !== '');
const alert = computed(() => store.state.alert);
const alertDontHide = computed(() => store.state.alertDontHide);
const isOpenDontHide = computed(() => store.state.alertDontHide.message !== '');
const orgId = computed(() => store.state.profile.current_org.org_id);
const modalIsOpen = ref(false);
const slideOpen = ref(false);
const release = ref({});
const layout = computed(() => route.meta?.layout || _LAYOUT_NAME.NONE_LAYOUT);
const summary = ref({});

onMounted(() => {
	getNewRelease();
	getYearSummary();
});

const getCookie = (cookieName) => {
	const cookies = document.cookie.split(';');
	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i].trim();
		if (cookie.indexOf(cookieName + '=') === 0) {
			return cookie.substring(cookieName.length + 1, cookie.length);
		}
	}
	return null;
};

const getNewRelease = async () => {
	if (route.path.includes('pdf')) return;
	let hasShown = getCookie('hasShown');
	if (hasShown) return;
	const res = await $api.release.get();
	if (res.data.data) {
		release.value = res.data.data;
		modalIsOpen.value = true;
	}
	document.cookie = 'hasShown=' + true;
};

const getYearSummary = async () => {
	let showSlide = getCookie('showSlide');
	if (showSlide) return;
	let date = '2024-01-01';
	const res = await $api.release.getYearSummary(orgId.value, date);
	if (res.data.data) {
		summary.value = res.data.data;
		setTimeout(() => {
			slideOpen.value = true;
		}, 2000);
	}
	document.cookie = 'showSlide=' + true;
};
</script>
