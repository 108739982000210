<template>
	<!--  Take leave-->
	<div
		v-if="isTakeLeaveModal"
		:class="isTakeLeaveModal ? 'side-over--active' : ''"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isTakeLeaveModal"
			@click="isTakeLeaveModal = false"
		></div>
		<TakeLeaveForm
			@success="takeLeaveSuccess"
			@close="isTakeLeaveModal = false"
			:data="dataTakeLeave"
			:is-classed="true"
			:staffs_map="staffs_map"
			:students_map="students_map"
		/>
	</div>

	<!--  update session-->
	<div
		v-if="isUpdateSessionModal"
		:class="isUpdateSessionModal ? 'side-over--active' : ''"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isUpdateSessionModal"
			@click="isUpdateSessionModal = false"
		></div>
		<UpdateSessionForm
			@success="updateSessionSuccess"
			@close="isUpdateSessionModal = false"
			:data="[dataUpdateSession]"
			:staffs_map="staffs_map"
			:students_map="students_map"
		/>
	</div>

	<!--  change staff-->
	<div
		v-if="isChangeStaffModal"
		:class="isChangeStaffModal ? 'side-over--active' : ''"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isChangeStaffModal"
			@click="isChangeStaffModal = false"
		></div>
		<ChangeStaffForm
			@success="changeStaffSuccess"
			@close="isChangeStaffModal = false"
			:data="dataChangeStaff"
			:is-classed="true"
			:staffs_map="staffs_map"
			:students_map="students_map"
			:staff-options="staffOptions"
		/>
	</div>

	<!--  change time slot-->
	<div
		v-if="isChangeTimeSlotModal"
		:class="isChangeTimeSlotModal ? 'side-over--active' : ''"
	>
		<div
			class="
				fixed
				h-screen
				w-screen
				top-0
				left-0
				bg-black
				opacity-20
				z-30
			"
			v-if="isChangeTimeSlotModal"
			@click="isChangeTimeSlotModal = false"
		></div>
		<ChangeTimeSlot
			@success="changeTimeSlotSuccess"
			@close="isChangeTimeSlotModal = false"
			:data="dataChangeTimeSlot"
			:staffs_map="staffs_map"
			:students_map="students_map"
		/>
	</div>

	<!--  restore cancel session-->
	<DaModalFrame v-model:open="modalIsOpen">
		<DeleteTimeSlotConfirm
			v-if="deleteConfirm"
			@close="closeRestoreCancelSession"
			@submit="deleteTimeSlotSubmit"
		/>
		<RestoreCancelSession
			v-else
			:data="idRestore"
			@close="closeRestoreCancelSession"
			@success="restoreSessionSuccess"
		/>
	</DaModalFrame>

	<div v-if="!isLoading" class="my-10">
		<div
			class="
				flex
				items-start
				3xl:items-end
				justify-between
				3xl-down:flex-col
				gap-10
			"
		>
			<div class="w-full grid grid-cols-2 md:flex gap-8 flex-wrap">
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Ngày
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="dateSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								dates?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn ngày"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Trị liệu viên
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="staffSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								[
									{
										name: 'Tất cả',
										id: null,
										value: null,
									},
									...staffs,
								]?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn trị liệu viên"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Học sinh
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="studentSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								[
									{
										name: 'Tất cả',
										id: null,
										value: null,
									},
									...students,
								]?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn học sinh"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div>
					<div class="font-semibold uppercase text-gray-400 text-xs">
						Trạng thái
					</div>
					<div class="mt-2 w-full md:w-40">
						<Multiselect
							v-model="stateSelected"
							deselect-label="Đã chọn"
							track-by="id"
							:options="
								states?.map((s) => {
									return {
										nameSearch: toEnglish(s?.name),
										...s,
									};
								})
							"
							:searchable="true"
							label="nameSearch"
							:allow-empty="false"
							selectLabel=""
							placeholder="Chọn trạng thái"
						>
							<template #noResult>
								Không tìm thấy kết quả
							</template>
							<template v-slot:option="props">
								<div class="flex flex-nowrap items-center">
									<div>
										{{ props.option.name }}
									</div>
								</div>
							</template>
							<template v-slot:singleLabel="{ option }">
								<div
									class="
										flex flex-nowrap
										items-center
										font-medium
									"
								>
									<div>{{ option.name }}</div>
								</div>
							</template>
						</Multiselect>
					</div>
				</div>
				<div
					class="
						flex
						items-end
						pb-2
						cursor-pointer
						font-semibold
						text-gray-400
						hover:text-gray-600
					"
					@click="removeFilter"
				>
					Bỏ bộ lọc
				</div>
			</div>
			<div
				class="
					md-down:w-full
					grid grid-cols-2
					md:flex
					items-end
					justify-start
					md:justify-end
					gap-4
					md-down:mt-4
				"
			>
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						w-full
						px-4
						btn--ghost
					"
					:disabled="!isTakeLeave"
					@click="downloadCSVSchedule"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.0842 4.92956C9.93745 4.92956 9.79691 4.87117 9.69326 4.76725C9.58961 4.66322 9.53137 4.52232 9.53137 4.37535V0H3.54448C2.95814 0 2.39559 0.233557 1.981 0.649336C1.56628 1.06499 1.33331 1.62898 1.33331 2.21681V13.7832C1.33331 14.371 1.56628 14.935 1.981 15.3507C2.39559 15.7664 2.95814 16 3.54448 16H12.4555C13.0418 16 13.6044 15.7664 14.019 15.3507C14.4337 14.935 14.6666 14.371 14.6666 13.7832V4.92987L10.0842 4.92956ZM12.732 12.7106C12.732 12.8575 12.6738 12.9986 12.5701 13.1025C12.4663 13.2064 12.3258 13.2648 12.1792 13.2648H3.821C3.67441 13.2648 3.53387 13.2064 3.4301 13.1025C3.32645 12.9986 3.26821 12.8575 3.26821 12.7106V7.99984C3.26821 7.85288 3.32645 7.71185 3.4301 7.60794C3.53387 7.50403 3.67441 7.44564 3.821 7.44564H12.1792C12.3258 7.44564 12.4663 7.50403 12.5701 7.60794C12.6738 7.71185 12.732 7.85288 12.732 7.99984V12.7106ZM4.37379 10.9094H7.44735V12.1564H4.37379V10.9094ZM4.37379 8.55677H7.44735V9.801H4.37379V8.55677ZM8.5529 8.55405H11.6265V9.79828H8.5529V8.55405ZM8.5529 10.9067H11.6265V12.1564H8.5529V10.9067ZM14.3323 3.82112H10.6371V0.318558L14.3323 3.82112Z"
							fill="currentColor"
						/>
					</svg>

					<span class="ml-2">Tải file .XLXS</span>
				</button>
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						w-full
						px-4
						btn--ghost
					"
					:disabled="!isStaffCanChanged"
					@click="openModalChangeStaff"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M11.5337 7H15.4662C15.6781 7 15.7939 7.24721 15.6582 7.41005L13.692 9.76953C13.592 9.88947 13.4078 9.88947 13.3079 9.76953L11.3416 7.41005C11.2059 7.24721 11.3217 7 11.5337 7Z"
							fill="currentColor"
						/>
						<path
							d="M0.533681 9H4.46616C4.67812 9 4.79391 8.75279 4.65822 8.58995L2.69198 6.23047C2.59203 6.11053 2.40781 6.11053 2.30787 6.23047L0.341625 8.58995C0.205932 8.75279 0.321722 9 0.533681 9Z"
							fill="currentColor"
						/>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M7.99992 3C6.44743 3 5.06049 3.70697 4.14261 4.81839C3.96677 5.03131 3.65161 5.06137 3.43869 4.88552C3.22578 4.70968 3.19572 4.39453 3.37156 4.18161C4.47126 2.85003 6.13654 2 7.99992 2C10.9416 2 13.3879 4.1165 13.901 6.90967C13.9065 6.9397 13.9118 6.96981 13.9169 7H12.9C12.437 4.71778 10.4186 3 7.99992 3ZM3.09984 9C3.56285 11.2822 5.58129 13 7.99992 13C9.55241 13 10.9394 12.293 11.8572 11.1816C12.0331 10.9687 12.3482 10.9386 12.5611 11.1145C12.7741 11.2903 12.8041 11.6055 12.6283 11.8184C11.5286 13.15 9.86331 14 7.99992 14C5.05822 14 2.6119 11.8835 2.09884 9.09033C2.09332 9.0603 2.08803 9.03019 2.08296 9H3.09984Z"
							fill="currentColor"
						/>
					</svg>

					<span class="ml-2">Đổi trị liệu viên</span>
				</button>
				<button
					type="button"
					class="
						button-p
						text-center
						whitespace-nowrap
						w-full
						px-4
						btn--ghost
					"
					:disabled="!isTakeLeave"
					@click="openModalTakeLeave"
				>
					<svg
						width="16"
						height="16"
						viewBox="0 0 16 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
							fill="currentColor"
						/>
					</svg>

					<span class="ml-2">Báo nghỉ</span>
				</button>
			</div>
		</div>
		<div
			v-if="dateSelected && dateSelected.id === 'custom'"
			class="flex items-center gap-8 mt-8"
		>
			<div class="w-56">
				<div class="font-semibold uppercase text-gray-400 text-xs">
					Từ ngày
				</div>
				<div class="w-full mt-2">
					<div class="input-group flex items-center">
						<VueDatepicker
							uid="session-by-from-date"
							v-model="fromDate"
							:enableTimePicker="false"
							class="input-date w-full"
							placeholder="Chọn ngày bắt đầu"
							format="dd/MM/yyyy"
							autoApply
						>
							<template #input-icon>
								<DaIcon
									name="das--lock"
									class="h-4 w-4 text-neutral-400"
								/>
							</template>
						</VueDatepicker>
					</div>
				</div>
			</div>
			<div class="w-56">
				<div class="font-semibold uppercase text-gray-400 text-xs">
					Đến ngày
				</div>
				<div class="w-full mt-2">
					<div class="input-group flex items-center">
						<VueDatepicker
							uid="session-by-to-date"
							v-model="toDate"
							:enableTimePicker="false"
							class="input-date w-full"
							placeholder="Chọn ngày kết thúc"
							format="dd/MM/yyyy"
							autoApply
						>
							<template #input-icon>
								<DaIcon
									name="das--lock"
									class="h-4 w-4 text-neutral-400"
								/>
							</template>
						</VueDatepicker>
					</div>
				</div>
			</div>
		</div>
		<div v-if="data && data.length" class="overflow-x-auto">
			<div class="shadow-sm sm:rounded-2xl mt-10 mb-40">
				<div class="__record__table scroll-bar">
					<div class="sm:rounded-t-2xl w-fit min-w-full">
						<table class="min-w-full divide-y text-neutral-200">
							<thead class="bg-neutral-100 h-16">
								<tr>
									<th class="px-4">
										<Checkbox v-model:value="checkBoxAll" />
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ngày
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Giờ học
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Trị liệu viên/ Lớp
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Tên học sinh
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Trạng thái
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Phân loại
									</th>
									<th
										scope="col"
										class="
											px-3
											py-3
											text-left text-xs
											font-semibold
											text-neutral-600
											uppercase
											tracking-wider
											whitespace-nowrap
										"
									>
										Ghi chú
									</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(item, index) in data"
									:key="index"
									:class="
										item.state === 'cancelled'
											? 'bg-sienna-50'
											: index % 2 === 0
											? 'bg-white'
											: 'bg-neutral-50'
									"
									class="text-neutral-700"
								>
									<td class="px-4">
										<Checkbox
											:value="
												checkBoxSelected.find(
													(i) => i === index + 1
												)
											"
											@click="checkChanged(index + 1)"
										/>
									</td>
									<td>
										<div class="px-3 py-4 text-neutral-700">
											<span v-if="item.date">{{
												$filters.formatDate2(item.date)
											}}</span>
										</div>
									</td>
									<td>
										<div class="px-3 py-4 text-neutral-700">
											<span
												v-if="item.display_start_slot"
												>{{
													item.display_start_slot
												}}</span
											>
											<span v-if="item.display_end_slot">
												-
												{{
													item.display_end_slot
												}}</span
											>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											v-if="item.staff_ids"
											class="flex flex-col gap-2"
										>
											<div
												@click.stop="
													$router.push(
														`/record/employee/${staff_id}`
													)
												"
												class="flex items-center"
												v-for="staff_id in item.staff_ids"
												:key="staff_id"
											>
												<div v-if="getStaff(staff_id)">
													<img
														class="
															shadow-sm
															inline-block
															h-9
															w-9
															rounded-full
														"
														:src="
															getStaff(staff_id)
																?.profile_photo
																?.w200
														"
														alt=""
														v-if="
															getStaff(staff_id)
																?.profile_photo
																?.w200
														"
													/>
													<span
														v-else
														class="
															inline-flex
															items-center
															justify-center
															h-9
															w-9
															rounded-full
														"
														:style="{
															background:
																getStaff(
																	staff_id
																)?.profile_photo
																	?.default
																	?.color,
														}"
													>
														<span
															class="
																font-medium
																leading-none
																text-white
																text-xs
															"
															>{{
																getStaff(
																	staff_id
																)?.profile_photo
																	?.default
																	?.initial
															}}</span
														>
													</span>
												</div>
												<div
													v-if="getStaff(staff_id)"
													class="
														ml-4
														text-blue-600
														hover:text-blue-700
														hover:underline
														cursor-pointer
														font-semibold
														whitespace-pre-wrap
														w-40
													"
												>
													{{
														getStaff(staff_id).name
													}}
												</div>
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											@click.stop="
												$router.push(
													`/record/student/${item.student_id}`
												)
											"
											class="flex items-center"
											v-if="
												item.student_id &&
												getStudent(item.student_id)
											"
										>
											<div>
												<img
													class="
														shadow-sm
														inline-block
														h-9
														w-9
														rounded-full
													"
													:src="
														getStudent(
															item.student_id
														)?.profile_photo?.w200
													"
													alt=""
													v-if="
														getStudent(
															item.student_id
														)?.profile_photo?.w200
													"
												/>
												<span
													v-else
													class="
														inline-flex
														items-center
														justify-center
														h-9
														w-9
														rounded-full
													"
													:style="{
														background: getStudent(
															item.student_id
														)?.profile_photo
															?.default?.color,
													}"
												>
													<span
														class="
															font-medium
															leading-none
															text-white text-xs
														"
														>{{
															getStudent(
																item.student_id
															)?.profile_photo
																?.default
																?.initial
														}}</span
													>
												</span>
											</div>
											<div
												class="
													ml-4
													text-yellow-600
													font-semibold
													whitespace-pre-wrap
													w-40
													hover:text-yellow-700
													hover:underline
													cursor-pointer
												"
											>
												{{
													getStudent(item.student_id)
														.name
												}}
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="
													item.state !== 'created' &&
													item.state !==
														'cancelled' &&
													item.state !== 'ongoing'
												"
												class="
													text-xs
													w-fit
													font-semibold
													bg-neutral-100
													text-gray-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Chưa học</div>
											</div>
											<div
												v-if="item.state === 'ongoing'"
												class="
													w-fit
													font-semibold
													bg-blue-100
													text-xs text-blue-600
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.5 14C11.366 14 14.5 10.866 14.5 7C14.5 3.13401 11.366 0 7.5 0C3.63401 0 0.5 3.13401 0.5 7C0.5 10.866 3.63401 14 7.5 14ZM11.0491 6.63407C10.9689 6.50148 10.846 6.41667 10.7083 6.41667H4.58333C4.44564 6.41667 4.3228 6.50148 4.2426 6.63407C4.14461 6.72535 4.08333 6.85552 4.08333 7C4.08333 7.14448 4.14461 7.27465 4.2426 7.36593C4.3228 7.49852 4.44564 7.58333 4.58333 7.58333H10.7083C10.846 7.58333 10.9689 7.49852 11.0491 7.36593C11.1471 7.27465 11.2083 7.14448 11.2083 7C11.2083 6.85552 11.1471 6.72535 11.0491 6.63407Z"
															fill="#CBD5E1"
														/>
													</svg>
												</div>
												<div>Đang học</div>
											</div>
											<div
												v-if="
													item.state === 'cancelled'
												"
												class="
													text-sm
													w-fit
													font-semibold
													bg-sienna-100
													text-sienna-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M5.19063 4.35739C5.36148 4.18653 5.63849 4.18653 5.80934 4.35739L8.12498 6.67303L10.4406 4.35739C10.6115 4.18653 10.8885 4.18653 11.0593 4.35739C11.2302 4.52824 11.2302 4.80525 11.0593 4.97611L8.7437 7.29175L11.0593 9.60739C11.2302 9.77824 11.2302 10.0553 11.0593 10.2261C10.8885 10.397 10.6115 10.397 10.4406 10.2261L8.12498 7.91047L5.80934 10.2261C5.63849 10.397 5.36148 10.397 5.19063 10.2261C5.01977 10.0553 5.01977 9.77824 5.19063 9.60739L7.50627 7.29175L5.19063 4.97611C5.01977 4.80525 5.01977 4.52824 5.19063 4.35739Z"
															fill="#DB5A41"
														/>
													</svg>
												</div>
												<div>
													{{
														item.student_on_leave
															? 'HS Nghỉ'
															: 'TLV Nghỉ'
													}}
												</div>
											</div>
											<div
												v-if="item.state === 'created'"
												class="
													text-xs
													w-fit
													font-semibold
													bg-green-50
													text-green-500
													p-2
													px-4
													pb-1.5
													rounded-full
													flex
													items-center
													justify-center
													gap-2
												"
											>
												<div>
													<svg
														width="15"
														height="14"
														viewBox="0 0 15 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M8.68176 4.34846C8.93804 4.09218 9.35355 4.09218 9.60983 4.34846C9.86297 4.6016 9.86608 5.01009 9.61915 5.26704L6.12597 9.63351C6.12093 9.63981 6.11554 9.64583 6.10983 9.65154C5.85355 9.90782 5.43804 9.90782 5.18176 9.65154L2.86612 7.3359C2.60983 7.07962 2.60983 6.6641 2.86612 6.40782C3.1224 6.15154 3.53791 6.15154 3.79419 6.40782L5.62602 8.23965L8.66435 4.36809C8.66975 4.36121 8.67557 4.35465 8.68176 4.34846Z"
															fill="#339588"
														/>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M7.87639 8.84617L8.68176 9.65154C8.93804 9.90782 9.35355 9.90782 9.60984 9.65154C9.61554 9.64583 9.62093 9.63981 9.62597 9.63351L13.1191 5.26704C13.3661 5.01009 13.363 4.6016 13.1098 4.34846C12.8536 4.09218 12.438 4.09218 12.1818 4.34846C12.1756 4.35465 12.1698 4.36121 12.1644 4.36809L9.12602 8.23965L8.70135 7.81497L7.87639 8.84617Z"
															fill="#339588"
														/>
													</svg>
												</div>
												<div>Đã học</div>
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div>
											<div
												v-if="!item.scheduled"
												class="
													text-xs
													w-fit
													font-semibold
													text-sienna-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-sienna-200
													flex
													items-center
													justify-center
												"
											>
												Phát sinh
											</div>
											<div
												v-else
												class="
													text-xs
													w-fit
													font-semibold
													text-neutral-600
													p-2
													px-4
													pb-1.5
													rounded-full
													border border-neutral-300
													flex
													items-center
													justify-center
												"
											>
												Cố định
											</div>
										</div>
									</td>
									<td
										class="
											px-3
											py-4
											whitespace-nowrap
											text-neutral-700
										"
									>
										<div
											class="text-neutral-600"
											style="
												max-width: 200px;
												white-space: break-spaces;
											"
										>
											<div v-if="item.unscheduled_note">
												<span class="font-semibold"
													>Phát sinh: </span
												>{{ item.unscheduled_note }}
											</div>
											<div v-if="item.leave_note">
												<span class="font-semibold"
													>Báo nghỉ: </span
												>{{ item.leave_note }}
											</div>
											<div v-if="item.staff_change_note">
												<span class="font-semibold"
													>Đổi TLV: </span
												>{{ item.staff_change_note }}
											</div>
											<div v-if="item.slot_change_note">
												<span class="font-semibold"
													>Đổi giờ:
												</span>
												{{ item.slot_change_note }}
											</div>
										</div>
									</td>
									<td>
										<Menu as="div" class="ml-3 relative">
											<div>
												<MenuButton
													class="
														rounded-full
														flex
														text-sm
														focus:outline-none
														focus:ring-2
														focus:ring-offset-2
														focus:ring-indigo-500
													"
												>
													<span class="sr-only"
														>Open user menu</span
													>
													<svg
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															fill-rule="evenodd"
															clip-rule="evenodd"
															d="M11.875 16.25C11.875 17.2855 11.0355 18.125 10 18.125C8.96447 18.125 8.125 17.2855 8.125 16.25C8.125 15.2145 8.96447 14.375 10 14.375C11.0355 14.375 11.875 15.2145 11.875 16.25ZM11.875 10C11.875 11.0355 11.0355 11.875 10 11.875C8.96447 11.875 8.125 11.0355 8.125 10C8.125 8.96447 8.96447 8.125 10 8.125C11.0355 8.125 11.875 8.96447 11.875 10ZM11.875 3.75C11.875 4.78553 11.0355 5.625 10 5.625C8.96447 5.625 8.125 4.78553 8.125 3.75C8.125 2.71447 8.96447 1.875 10 1.875C11.0355 1.875 11.875 2.71447 11.875 3.75Z"
															fill="#94A3B8"
														/>
													</svg>
												</MenuButton>
											</div>
											<transition
												enter-active-class="transition ease-out duration-200"
												enter-from-class="transform opacity-0 scale-95"
												enter-to-class="transform opacity-100 scale-100"
												leave-active-class="transition ease-in duration-75"
												leave-from-class="transform opacity-100 scale-100"
												leave-to-class="transform opacity-0 scale-95"
											>
												<MenuItems
													class="
														origin-top-right
														absolute
														right-0
														mt-2
														w-60
														rounded-md
														shadow-lg
														py-1
														bg-white
														ring-1
														ring-black
														ring-opacity-5
														focus:outline-none
														z-10
													"
												>
													<MenuItem
														v-if="
															item.state !==
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																selectedTakeLeave(
																	index + 1
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM5.35355 4.64645C5.15829 4.45118 4.84171 4.45118 4.64645 4.64645C4.45118 4.84171 4.45118 5.15829 4.64645 5.35355L7.29289 8L4.64645 10.6464C4.45118 10.8417 4.45118 11.1583 4.64645 11.3536C4.84171 11.5488 5.15829 11.5488 5.35355 11.3536L8 8.70711L10.6464 11.3536C10.8417 11.5488 11.1583 11.5488 11.3536 11.3536C11.5488 11.1583 11.5488 10.8417 11.3536 10.6464L8.70711 8L11.3536 5.35355C11.5488 5.15829 11.5488 4.84171 11.3536 4.64645C11.1583 4.45118 10.8417 4.45118 10.6464 4.64645L8 7.29289L5.35355 4.64645Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Báo nghỉ</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-if="
															item.state ===
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																selectedUpdateSession(
																	item
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M12.4702 6.6509L13.7102 5.41087C13.8461 5.27501 13.914 5.20708 13.9667 5.14512C14.6009 4.39844 14.6009 3.30226 13.9667 2.55558C13.914 2.49362 13.8461 2.42569 13.7102 2.28983C13.5744 2.15397 13.5064 2.08604 13.4445 2.03341C12.6978 1.39918 11.6016 1.39918 10.855 2.03341C10.793 2.08603 10.7251 2.15394 10.5893 2.28973L10.5892 2.28981L10.5892 2.28983L9.33099 3.54804C10.0747 4.84224 11.1583 5.91751 12.4702 6.6509ZM7.87633 5.0027L2.86273 10.0163C2.43767 10.4414 2.22514 10.6539 2.0854 10.915C1.94567 11.1761 1.88672 11.4708 1.76883 12.0603L1.37318 14.0385C1.30666 14.3712 1.27339 14.5375 1.368 14.6321C1.46261 14.7267 1.62892 14.6934 1.96153 14.6269L3.9398 14.2312L3.93982 14.2312L3.93984 14.2312C4.52927 14.1133 4.82399 14.0544 5.08508 13.9147C5.34617 13.7749 5.55869 13.5624 5.98373 13.1374L5.98376 13.1373L5.98377 13.1373L11.0118 8.10934C9.7511 7.31113 8.68202 6.24925 7.87633 5.0027Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Chỉnh sửa ghi
																chú</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-if="
															item.state ===
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																openRestoreCancelSession(
																	item
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	d="M11.5338 7H15.4663C15.6782 7 15.794 7.24721 15.6583 7.41005L13.6921 9.76953C13.5922 9.88947 13.4079 9.88947 13.308 9.76953L11.3417 7.41005C11.2061 7.24721 11.3218 7 11.5338 7Z"
																	fill="#94A3B8"
																/>
																<path
																	d="M0.533803 9H4.46628C4.67824 9 4.79403 8.75279 4.65834 8.58995L2.6921 6.23047C2.59215 6.11053 2.40794 6.11053 2.30799 6.23047L0.341748 8.58995C0.206054 8.75279 0.321844 9 0.533803 9Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M8.00004 3C6.44755 3 5.06061 3.70697 4.14273 4.81839C3.96689 5.03131 3.65174 5.06137 3.43882 4.88552C3.2259 4.70968 3.19584 4.39453 3.37168 4.18161C4.47139 2.85003 6.13666 2 8.00004 2C10.9417 2 13.3881 4.1165 13.9011 6.90967C13.9066 6.9397 13.9119 6.96981 13.917 7H12.9001C12.4371 4.71778 10.4187 3 8.00004 3ZM3.09996 9C3.56297 11.2822 5.58142 13 8.00004 13C9.55254 13 10.9395 12.293 11.8574 11.1816C12.0332 10.9687 12.3484 10.9386 12.5613 11.1145C12.7742 11.2903 12.8042 11.6055 12.6284 11.8184C11.5287 13.15 9.86343 14 8.00004 14C5.05834 14 2.61202 11.8835 2.09896 9.09033C2.09344 9.0603 2.08815 9.03019 2.08308 9H3.09996Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Khôi phục buổi
																học</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-if="
															item.state !==
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																selectedChangeStaff(
																	index + 1
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7 14C7 14 6 14 6 13C6 12 7 9 11 9C15 9 16 12 16 13C16 14 15 14 15 14H7Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M11 8C12.6569 8 14 6.65685 14 5C14 3.34315 12.6569 2 11 2C9.34315 2 8 3.34315 8 5C8 6.65685 9.34315 8 11 8Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M5.21636 14C5.07556 13.7159 5 13.3791 5 13C5 11.6445 5.67905 10.2506 6.93593 9.27997C6.3861 9.10409 5.7451 9 5 9C1 9 0 12 0 13C0 14 1 14 1 14H5.21636Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M4.5 8C5.88071 8 7 6.88071 7 5.5C7 4.11929 5.88071 3 4.5 3C3.11929 3 2 4.11929 2 5.5C2 6.88071 3.11929 8 4.5 8Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Đổi giáo
																viên</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-if="
															item.state !==
															'cancelled'
														"
														v-slot="{ active }"
													>
														<div
															@click="
																selectedChangeTimeSlot(
																	index + 1
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 3.5C8 3.22386 7.77614 3 7.5 3C7.22386 3 7 3.22386 7 3.5V9C7 9.17943 7.09614 9.3451 7.25193 9.43412L10.7519 11.4341C10.9917 11.5711 11.2971 11.4878 11.4341 11.2481C11.5711 11.0083 11.4878 10.7029 11.2481 10.5659L8 8.70984V3.5Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Đổi giờ
																học</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-if="!item.scheduled"
														v-slot="{ active }"
													>
														<div
															@click="
																openDeleteConfirmModal(
																	item.id ||
																		'id_' +
																			index
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="14"
																height="15"
																viewBox="0 0 14 15"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M1.5 1C0.947715 1 0.5 1.44772 0.5 2V3C0.5 3.55228 0.947715 4 1.5 4H2V13C2 14.1046 2.89543 15 4 15H10C11.1046 15 12 14.1046 12 13V4H12.5C13.0523 4 13.5 3.55228 13.5 3V2C13.5 1.44772 13.0523 1 12.5 1H9C9 0.447715 8.55229 0 8 0H6C5.44772 0 5 0.447715 5 1H1.5ZM4.5 5C4.77614 5 5 5.22386 5 5.5V12.5C5 12.7761 4.77614 13 4.5 13C4.22386 13 4 12.7761 4 12.5L4 5.5C4 5.22386 4.22386 5 4.5 5ZM7 5C7.27614 5 7.5 5.22386 7.5 5.5V12.5C7.5 12.7761 7.27614 13 7 13C6.72386 13 6.5 12.7761 6.5 12.5V5.5C6.5 5.22386 6.72386 5 7 5ZM10 5.5C10 5.22386 9.77614 5 9.5 5C9.22386 5 9 5.22386 9 5.5V12.5C9 12.7761 9.22386 13 9.5 13C9.77614 13 10 12.7761 10 12.5V5.5Z"
																	fill="#94A3B8"
																/>
															</svg>

															<span class="mt-0.5"
																>Xoá buổi
																học</span
															>
														</div>
													</MenuItem>
													<MenuItem
														v-slot="{ active }"
													>
														<div
															@click="
																downloadCSVScheduleOnly(
																	index + 1
																)
															"
															class="
																p-2
																px-4
																bg-white
																z-10
																text-gray-600
																hover:bg-neutral-50
																flex
																items-center
																gap-4
															"
															:class="
																active ? '' : ''
															"
														>
															<svg
																width="16"
																height="16"
																viewBox="0 0 16 16"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M8 0C6.57359 0 5.31176 0.560456 4.40576 1.34182C3.63985 2.00237 3.08479 2.8617 2.94223 3.725C1.26586 4.09512 0 5.55511 0 7.31818C0 9.36599 1.70754 11 3.78125 11H7.5V5.5C7.5 5.22386 7.72386 5 8 5C8.27614 5 8.5 5.22386 8.5 5.5V11H12.6875C14.5023 11 16 9.56949 16 7.77273C16 6.13656 14.7581 4.80411 13.1657 4.57898C12.9233 1.99944 10.6902 0 8 0Z"
																	fill="#94A3B8"
																/>
																<path
																	fill-rule="evenodd"
																	clip-rule="evenodd"
																	d="M7.64645 15.8536C7.84171 16.0488 8.15829 16.0488 8.35355 15.8536L11.3536 12.8536C11.5488 12.6583 11.5488 12.3417 11.3536 12.1464C11.1583 11.9512 10.8417 11.9512 10.6464 12.1464L8.5 14.2929V11H7.5V14.2929L5.35355 12.1464C5.15829 11.9512 4.84171 11.9512 4.64645 12.1464C4.45118 12.3417 4.45118 12.6583 4.64645 12.8536L7.64645 15.8536Z"
																	fill="#94A3B8"
																/>
															</svg>
															<span class="mt-0.5"
																>Tải file
																.XLXS</span
															>
														</div>
													</MenuItem>
												</MenuItems>
											</transition>
										</Menu>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-else
			class="
				w-full
				h-full
				flex
				items-center
				justify-center
				flex-col
				mt-10
			"
		>
			<img
				src="@/assets/images/illustrations/not_found.png"
				alt=""
				height="250"
				width="250"
			/>
			<div
				class="text-neutral-500 text-lg text-center"
				style="max-width: 700px"
			>
				<span
					v-if="
						staffSelected?.id ||
						studentSelected?.id ||
						stateSelected?.id ||
						dateSelected?.id !== 'today'
					"
					>Không có buổi học nào phù hợp. <br />
					Bạn vui lòng kiểm tra lại bộ lọc</span
				>
				<span v-else>Không có lịch học cá nhân hôm nay</span>
			</div>
		</div>
	</div>
	<div v-else class="fixed top-1/2 left-1/2">
		<Loading />
	</div>
</template>

<script>
import Checkbox from '@/components/elements/checkbox/Checkbox';
import { $api } from '@/services';
import Multiselect from '@suadelabs/vue3-multiselect';
import TakeLeaveForm from '@/views/sessionManagement/features/takeLeaveForm';
import { Menu, MenuItem, MenuItems, MenuButton } from '@headlessui/vue';
import UpdateSessionForm from '@/views/sessionManagement/features/updateSessionForm';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame';
import RestoreCancelSession from '@/views/sessionManagement/features/RestoreCancelSession';
import ChangeStaffForm from '@/views/sessionManagement/features/changedStaffForm';
import ChangeTimeSlot from '@/views/sessionManagement/features/changeTimeSlot';
import DeleteTimeSlotConfirm from '@/views/sessionManagement/features/DeleteTimeSlotConfirm';
import DaIcon from '@/components/elements/daIcon/DaIcon';
import dayjs from 'dayjs';
import Loading from '@/components/common/Loading';
import DownloadCSV from '@/mixins/downloadCSV';
import { toEnglish } from '@/helper/toEnglish';

export default {
	name: 'SessionManagementClass',
	components: {
		Loading,
		DaIcon,
		DeleteTimeSlotConfirm,
		ChangeTimeSlot,
		ChangeStaffForm,
		RestoreCancelSession,
		DaModalFrame,
		UpdateSessionForm,
		TakeLeaveForm,
		Checkbox,
		Multiselect,
		Menu,
		MenuItem,
		MenuItems,
		MenuButton,
	},
	data() {
		return {
			isLoading: true,
			checkBoxAll: false,
			dateSelected: {
				id: 'today',
				name: 'Hôm nay',
			},
			dates: [
				{
					id: 'yesterday',
					name: 'Hôm qua',
				},
				{
					id: 'today',
					name: 'Hôm nay',
				},
				{
					id: 'this_week',
					name: 'Tuần này',
				},
				{
					id: 'this_month',
					name: 'Tháng này',
				},
				{
					id: 'last_month',
					name: 'Tháng trước',
				},
				{
					id: 'custom',
					name: 'Tuỳ chọn',
				},
			],
			toDate: null,
			fromDate: null,
			staffs: [],
			students: [],
			stateSelected: {
				name: 'Tất cả',
				id: null,
			},
			studentSelected: {
				name: 'Tất cả',
				id: null,
				value: null,
			},
			staffSelected: {
				name: 'Tất cả',
				id: null,
				value: null,
			},
			states: [
				{
					id: '',
					name: 'Tất cả',
				},
				{
					id: 'created',
					name: 'Đã học',
				},
				{
					id: 'future',
					name: 'Chưa học',
				},
				{
					id: 'cancelled',
					name: 'Nghỉ học',
				},
			],
			data: [],
			staffs_map: {},
			students_map: {},
			params: {
				duration: 'today',
				student_id: undefined,
				staff_id: undefined,
				state: undefined,
			},
			checkBoxSelected: [],
			isTakeLeaveModal: false,
			isChangeStaffModal: false,
			isUpdateSessionModal: false,
			isChangeTimeSlotModal: false,
			dataTakeLeave: [],
			dataChangeStaff: [],
			staffOptions: [],
			dataChangeTimeSlot: [],
			dataUpdateSession: null,
			modalIsOpen: false,
			idRestore: '',
			idDelete: '',
			isStaffCanChanged: false,
			deleteConfirm: false,
		};
	},

	created() {
		const month = this.$route?.query?.month;
		if (month) {
			this.dateSelected = {
				id: 'custom',
				name: 'Tuỳ chọn',
			};
			this.fromDate = dayjs(
				new Date(new Date().getFullYear(), month - 1, 1)
			);
			this.toDate = dayjs(this.fromDate).endOf('month');
		}
		this.fetchSessionOnToDay();
		this.fetchStaffs();
		this.fetchStudents();
	},

	computed: {
		isTakeLeave() {
			return this.checkBoxSelected.length;
		},
	},

	watch: {
		staffSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.params = {
						...this.params,
						staff_id: value?.id,
					};
					this.fetchSessionOnToDay();
				}
			},
		},
		studentSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.params = {
						...this.params,
						student_id: value?.id,
					};
					this.fetchSessionOnToDay();
				}
			},
		},
		stateSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.params = {
						...this.params,
						state: value?.id,
					};
					this.fetchSessionOnToDay();
				}
			},
		},
		dateSelected: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value && value.id !== 'custom') {
					this.params = {
						...this.params,
						duration: value.id || 'today',
					};
					this.fetchSessionOnToDay();
				}
			},
		},

		fromDate: {
			deep: true,
			immediate: true,
			handler(value) {
				console.log(value);
				if (
					value &&
					this.dateSelected &&
					this.dateSelected.id === 'custom' &&
					this.toDate
				) {
					this.params = {
						...this.params,
						duration: 'custom',
						to_date: dayjs(this.toDate).format('YYYY-MM-DD'),
						from_date: dayjs(value).format('YYYY-MM-DD'),
					};
					this.fetchSessionOnToDay();
				}
			},
		},

		toDate: {
			deep: true,
			immediate: true,
			handler(value) {
				if (
					value &&
					this.dateSelected &&
					this.dateSelected.id === 'custom' &&
					this.fromDate
				) {
					this.params = {
						...this.params,
						duration: 'custom',
						from_date: dayjs(this.fromDate).format('YYYY-MM-DD'),
						to_date: dayjs(value).format('YYYY-MM-DD'),
					};
					this.fetchSessionOnToDay();
				}
			},
		},

		checkBoxSelected: {
			deep: true,
			handler(value) {
				if (value) {
					const object = this.data
						.filter((item, index) => value.includes(index + 1))
						.map((item) => item.staff_ids);
					let result = object[0];

					object.forEach((item) => {
						result = result.filter((r) => item.includes(r));
					});

					this.staffOptions = result;
					this.isStaffCanChanged = result?.length;
				}
			},
		},

		checkBoxAll(value) {
			if (value) {
				this.checkBoxSelected = this.data.map(
					(item, index) => index + 1
				);
			} else {
				this.checkBoxSelected = [];
			}
		},
	},

	methods: {
		toEnglish,
		removeFilter() {
			this.params = {
				duration: 'today',
				student_id: undefined,
				day_care_class_id: undefined,
				state: undefined,
			};
			this.studentSelected = {
				name: 'Tất cả',
				id: null,
				value: null,
			};
			this.stateSelected = {
				name: 'Tất cả',
				id: null,
				value: null,
			};
			this.staffSelected = {
				name: 'Tất cả',
				id: null,
				value: null,
			};
			this.dateSelected = {
				id: 'today',
				name: 'Hôm nay',
			};
			this.fromDate = null;
			this.toDate = null;

			this.fetchSessionOnToDay();
		},

		checkChanged(id) {
			const result = this.checkBoxSelected.find((item) => item === id);

			if (result) {
				this.checkBoxSelected = this.checkBoxSelected.filter((item) => {
					return item !== id;
				});
			} else {
				this.checkBoxSelected.push(id);
			}
		},

		async deleteTimeSlotSubmit() {
			try {
				await $api.schedule.deleteTimeSlot(this.idDelete);
				await this.fetchSessionOnToDay();
				this.closeRestoreCancelSession();
				await this.$store.dispatch('setAlertMessage', {
					message: 'Xoá thành công buổi học',
				});
				this.idDelete = '';
			} catch (e) {
				const error =
					e?.response?.data?.errors?.join('<br/>') ||
					'Có lỗi xảy ra, vui lòng thử lại';
				await this.$store.dispatch('setAlertMessage', {
					message: error,
					type: 'danger',
				});
			}
		},

		openModalTakeLeave() {
			this.dataTakeLeave = this.data.filter((item, index) =>
				this.checkBoxSelected.includes(index + 1)
			);
			this.isTakeLeaveModal = true;
		},

		openModalChangeTimeSlot() {
			this.dataChangeTimeSlot = this.data.filter((item, index) =>
				this.checkBoxSelected.includes(index + 1)
			);
			this.isChangeTimeSlotModal = true;
		},

		openModalChangeStaff() {
			this.dataChangeStaff = this.data.filter((item, index) =>
				this.checkBoxSelected.includes(index + 1)
			);
			this.isChangeStaffModal = true;
		},

		openModalUpdateSession() {
			this.dataTakeLeave = this.data.filter((item, index) =>
				this.checkBoxSelected.includes(index + 1)
			);
			this.isUpdateSessionModal = true;
		},

		async fetchSessionOnToDay() {
			try {
				const {
					data: { data, staffs_map, students_map },
				} = await $api.schedule.fetchSessionOnToDay(this.params);
				this.staffs_map = staffs_map || {};
				this.students_map = students_map || {};
				this.data = data;
				this.isLoading = false;
			} catch (e) {
				console.log(e);
			}
		},

		getStaff(staff_id) {
			return this.staffs_map[staff_id]?.[0];
		},

		getStudent(student_id) {
			return this.students_map[student_id]?.[0];
		},

		async fetchStaffs() {
			try {
				const {
					data: { data },
				} = await $api.staff.getActiveEmployees('');
				this.staffs = data;
			} catch (err) {
				console.log(err);
			}
		},

		async fetchStudents() {
			let role =
				this.$store.state.profile.staff.role === 'teacher'
					? 'staff'
					: 'org_admin';
			try {
				const {
					data: { data },
				} = await $api.student.getStudents(role, '');
				this.students = data;
			} catch (err) {
				console.log(err);
			}
		},

		selectedTakeLeave(id) {
			this.checkBoxSelected = [id];
			this.openModalTakeLeave();
		},

		selectedChangeStaff(id) {
			this.checkBoxSelected = [id];
			this.openModalChangeStaff();
		},

		selectedChangeTimeSlot(id) {
			this.checkBoxSelected = [id];
			this.openModalChangeTimeSlot();
		},

		selectedUpdateSession(item) {
			this.dataUpdateSession = item;
			this.openModalUpdateSession();
		},

		takeLeaveSuccess() {
			this.checkBoxSelected = [];
			this.isTakeLeaveModal = false;
			this.fetchSessionOnToDay();
		},

		changeStaffSuccess() {
			this.checkBoxSelected = [];
			this.isChangeStaffModal = false;
			this.fetchSessionOnToDay();
		},

		changeTimeSlotSuccess() {
			this.checkBoxSelected = [];
			this.isChangeTimeSlotModal = false;
			this.fetchSessionOnToDay();
		},

		updateSessionSuccess() {
			this.isUpdateSessionModal = false;
			this.dataUpdateSession = null;
			this.fetchSessionOnToDay();
		},

		openRestoreCancelSession(data) {
			this.idRestore = data;
			this.modalIsOpen = true;
			this.deleteConfirm = false;
		},

		closeRestoreCancelSession() {
			this.idRestore = '';
			this.idDelete = '';
			this.modalIsOpen = false;
			this.deleteConfirm = false;
		},

		openDeleteConfirmModal(id) {
			this.idDelete = id;
			this.modalIsOpen = true;
			this.deleteConfirm = true;
		},

		restoreSessionSuccess() {
			this.closeRestoreCancelSession();
			this.fetchSessionOnToDay();
		},

		downloadCSVSchedule() {
			const dataDownload = this.data
				.filter((item, index) =>
					this.checkBoxSelected.includes(index + 1)
				)
				.map((item) => {
					return item.state === 'future'
						? {
								t_id: item.time_slot_id,
								date: item.date,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  }
						: {
								s_id: item.id,
								// eslint-disable-next-line no-mixed-spaces-and-tabs
						  };
				});
			this.downloadCSVScheduleService({
				session_and_time_slot_id_with_dates: dataDownload,
			});
		},

		downloadCSVScheduleOnly(id) {
			this.checkBoxSelected = [id];
			this.downloadCSVSchedule();
		},
	},

	mixins: [DownloadCSV],
};
</script>
