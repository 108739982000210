
import DaIcon from '@/components/elements/daIcon/DaIcon.vue';
import Rating from '@/components/elements/rating/Rating.vue';
import { Options, Vue, setup } from 'vue-class-component';
import { useStore } from 'vuex';
import DaInfinityLoading from '@/components/common/daInfinityLoading/DaInfinityLoading.vue';
import { $api } from '@/services';
import { useStudent } from '@/features/student/useStudent';
import Loading from '@/components/common/Loading.vue';
import DaModalFrame from '@/components/common/daModalFrame/DaModalFrame.vue';
import { _MODAL_TYPE } from '@/enums';
import ConfirmSendMonthlyReport from '@/views/planGroupDetail/ModalConfirmSendReport.vue';
import Modal from '@/views/report/features/plans/Modal.vue';
import { Watch } from 'vue-property-decorator';

@Options({
	name: 'PlansGroup',
	components: {
		Modal,
		ConfirmSendMonthlyReport,
		DaModalFrame,
		Loading,
		Rating,
		DaIcon,
		DaInfinityLoading,
	},
})
export default class ReportPlans extends Vue {
	monthlyReportList: any = [];
	beforeDate = '';
	stopLoad = false;
	students: any = [];
	sideOverShow = false;
	payload: any = null;
	isInfinityLoading = false;
	searchQuery = '';
	alertShow = true;
	isLoading = true;
	isOpenModal = false;
	sendData = null as any;
	modalType = '';
	isHides: any = [];
	dataUncompleted: any = [];
	dataObject: any = null;

	//----------------------- 🤍 Setup 🤍 -----------------------//
	setup = setup(() => {
		const store = useStore();
		const { getStudents } = useStudent();
		return {
			store,
			getStudents,
		};
	});

	//----------------------- 🤍 Getters 🤍 -----------------------//
	get isAdmin() {
		return this.setup.store.getters.isAdmin;
	}

	get monthlyReports() {
		return this.monthlyReportList;
	}

	get store() {
		return this.setup.store;
	}

	get modalTypes() {
		return _MODAL_TYPE;
	}

	@Watch('$route.query', {
		deep: true,
		immediate: true,
	})
	onRouteChange(value: any) {
		this.payload = {
			...this.payload,
			state: value?.state,
			group_class_id: value?.group_class_id,
			month_start: value?.month_start,
		};
		this.monthlyReportList = [];
		this.beforeDate = '';
		this.payload = {
			...this.payload,
			before: undefined,
		};
		this.getMonthlyReports();
	}

	clean(obj: any) {
		for (let propName in obj) {
			if (
				obj[propName] === null ||
				obj[propName] === undefined ||
				obj[propName].length <= 0
			) {
				delete obj[propName];
			}
		}
		return obj;
	}

	showHide(date: any) {
		const index = this.isHides.indexOf(date);
		if (index !== -1) {
			this.isHides.splice(index, 1);
		} else {
			this.isHides.push(date);
		}
	}

	async getMonthlyReports() {
		try {
			this.clean(this.payload);
			const { data }: any =
				await $api.monthlyReport.monthlyReportsListGroupClass(
					this.payload
				);
			if (data && data.data.length > 0) {
				this.monthlyReportList.push({
					date: data?.month_start || data?.data[0].month_start,
					data: data?.data,
					stats: data?.stats,
				});
				this.beforeDate =
					data?.month_start || data?.data[0].month_start;
				this.payload = {
					...this.payload,
					before: this.beforeDate,
				};
			} else {
				this.stopLoad = true;
			}
			this.isLoading = false;
			this.isInfinityLoading = true;
		} catch (e) {
			this.stopLoad = true;
			this.isInfinityLoading = false;
		}
	}

	async checkOpenModal(data: any) {
		await this.fetchUncompleted(data);
	}

	async fetchUncompleted(item: any) {
		try {
			if (item?.state === 'completed') {
				this.$router.push({
					path: `/report/monthly-report/group-class/${item?.group_class?.id}/detail`,
					query: {
						report_id: item?.id,
						month_start: item?.month_start,
					},
				});
				return;
			}
			const res = await $api.monthlyReport.unCompletedListGroupClass(
				item?.group_class?.id,
				item?.month_start
			);
			if (
				!res.data.data.length &&
				res.data.previous_report.state == 'completed'
			)
				this.$router.push({
					path: `/report/monthly-report/group-class/${item?.group_class?.id}/edit`,
					query: {
						report_id: item?.id,
						month_start: item?.month_start,
					},
				});
			else {
				this.dataObject = item;
				this.isOpenModal = true;
				this.modalType = _MODAL_TYPE.ERROR;
				this.dataUncompleted = res.data;
			}
		} catch (err) {
			console.log(err);
		}
	}

	closeModal() {
		this.dataUncompleted = [];
		this.isOpenModal = false;
		this.modalType = '';
	}

	openSendConfirm(data: any) {
		this.sendData = data;
		this.isOpenModal = true;
		this.modalType = _MODAL_TYPE.CREATE;
	}

	async sendReport() {
		try {
			await $api.monthlyReport.sendMonthlyReportGroupClass(
				this.sendData?.group_class?.id,
				this.sendData?.id
			);
			this.closeModal();
			await this.store.dispatch('setAlertMessage', {
				message: 'Gửi kế hoạch đến phụ huynh thành công',
			});
			this.sendData.sent_at = new Date();
		} catch (err) {
			await this.store.dispatch('setAlertMessage', {
				message: 'Đã có lỗi xảy ra. Vui lòng thử lại',
				type: 'danger',
			});
		}
	}

	beforeDestroy() {
		this.isInfinityLoading = false;
	}
}
